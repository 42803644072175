import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Container, Toolbar } from '@mui/material';
import ButtonAppBar from './components/Appbar';
import ScrollToTopFAB from './components/ScrollToTopFab';
import Settings, { useSettings } from "./components/LayoutSettings";
import ImageFilter, { handleLoadNext } from './components/ImageLoader';
import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import CssBaseline from "@mui/material/CssBaseline";
import client from './utils/Client'

const PropsContext = React.createContext(null);

export function useProps() {
  const context = React.useContext(PropsContext);
  if (!context) throw new Error("useProps must be used within a PropsContext");
  return context;
}

function Contents({ layoutOpen, setLayoutOpen, mode, setMode, isFollowSysTheme, setIsFollowSysTheme }) {
  const [userInfo, setUserInfo] = useState(null)
  const [itemsData, setItemData] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [isNsfw, setIsNsfw] = useState(localStorage.getItem("userNsfw") ? localStorage.getItem("userNsfw") : 'false');
  const [hasMore, setHasMore] = useState(true);
  const [isPending, setIsPending] = useState(false);
  const [isReview, setIsReview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [searchParams, setSearchParams] = useState('');
  const { layout, columns, targetRowHeight, spacing, padding, width } = useSettings();

  const updateUserInfo = (clear = false) => {
    if (clear) { setUserInfo(null); return }
    client.get("/user/")
      .then(({ data }) => { setUserInfo(data) })
      .catch(() => { setUserInfo(null) })
  }

  const props = {
    userInfo, updateUserInfo,
    isNsfw, setIsNsfw,
    itemsData, setItemData,
    hasMore, setHasMore,
    loading, setLoading,
    isPending, setIsPending,
    isReview, setIsReview,
    pageNum, setPageNum,
    mode, setMode,
    isFollowSysTheme, setIsFollowSysTheme,
    layoutOpen, setLayoutOpen,
    filterOpen, setFilterOpen,
    searchParams, setSearchParams,
    layout, columns, targetRowHeight, spacing, padding, width
  }


  // 成熟的前端会自己刷新token
  useEffect(() => { updateUserInfo() }, []);

  useEffect(() => {

    // 开局先拿第一页的数据
    setHasMore(true);

    handleLoadNext(
      {
        itemsData: [],
        setItemData: setItemData,
        pageNum: 1,
        setPageNum: setPageNum,
        hasMore: true,
        setHasMore: setHasMore,
        isNsfw: isNsfw,
        isAdmin: !!userInfo?.is_staff,
        setLoading: setLoading,
        isPending: isPending,
        searchParams: searchParams,
      }
    )
  }, [userInfo, isPending, searchParams, isNsfw])

  return (

    <div className="App">
      <header className="App-header">
        <meta name="viewport" content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0" />

        <Container disableGutters>

          {/* 顶栏 */}
          <PropsContext.Provider value={props}>
            <ButtonAppBar />
            <ImageFilter />
          </PropsContext.Provider>

          <Toolbar />

          <ScrollToTopFAB />

          <PropsContext.Provider value={props}>
            <Outlet />
          </PropsContext.Provider>

        </Container>

      </header>
    </div>

  );
}

export default function App() {

  const follow = localStorage.getItem('ThemeFollow') === 'false' ? false : true;
  const [isFollowSysTheme, setIsFollowSysTheme] = useState(follow);
  const [mode, setMode] = useState(checkIsDarkMode());
  const [layoutOpen, setLayoutOpen] = useState(false);

  // 夜间模式
  function checkIsDarkMode() {
    if (isFollowSysTheme) {
      try {
        return window.matchMedia('(prefers-color-scheme: dark)').matches;
      } catch (err) {
        return false;
      }
    } else {
      return localStorage.getItem('NightMode') === 'true' ? true : false
    }
  }

  // mode对应Override部件样式
  const appTheme = createTheme({
    palette: {
      mode: mode ? 'dark' : 'light',
    },
    components: {
      MuiDialogContent: {
        styleOverrides: {
          root: {
            '&.Roadmap': {
              color: mode ? '#c9c2b7' : '#242525',
              background: mode ? '#242525' : '#eeeeee',
            },
            '&.About': {
              color: mode ? '#c9c2b7' : '#242525',
              background: mode ? '#242525' : '#eeeeee',
            },
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            color: mode ? '#c9c2b7' : '#242525',
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={appTheme}>

      <CssBaseline />

      <Settings layoutOpen={layoutOpen} setLayoutOpen={setLayoutOpen}>
        <Contents
          mode={mode}
          setMode={setMode}
          isFollowSysTheme={isFollowSysTheme}
          setIsFollowSysTheme={setIsFollowSysTheme}
          layoutOpen={layoutOpen}
          setLayoutOpen={setLayoutOpen}
        />
      </Settings>

    </ThemeProvider>
  )

};
