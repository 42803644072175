import * as React from 'react';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function AboutDialogContent() {
    return (
        <DialogContent className='Roadmap' sx={{ textAlign: 'start' }}>

            <Typography variant="h4" gutterBottom>
                Roadmap
            </Typography>

            <Alert severity="warning">
                注意，以下内容可能只是舞草为了测试（玩）Mui排版而乱写的，请自行辨别内容的真实性。
            </Alert>

            <br />

            <Typography variant="h6" gutterBottom>
                # What's next?
            </Typography>

            <FormControlLabel disabled control={<Checkbox checked />} label={<Typography variant="body1"><del>夜间模式</del></Typography>} />
            <Typography variant="body2" gutterBottom>
                最牛产品经理张小龙曾说：微信不会开发夜间模式，因为这样能让大家早点休息。然后为了我的眼睛，张小龙全家都起飞了。
            </Typography>

            <Divider />

            <FormControlLabel disabled control={<Checkbox checked />} label={<Typography variant="body1"><del>瀑布流顺序</del></Typography>} />
            <Typography variant="body2" gutterBottom>
                我们留意到目前瀑布流并不会总是取最短列新增数据，而且瀑布流的排列是按列从上往下拍好1列再到第2列，这不是我们预期的表现。
            </Typography>

            <Divider />

            <FormControlLabel disabled control={<Checkbox checked />} label={<Typography variant="body1"><del>Yet Another React Lightbox</del></Typography>} />
            <Typography variant="body2" gutterBottom>
                目前使用的Gallery库不能满足展开后显示更多详细信息的需求，且对视频的支持官方sample不佳无法取得稳定的展示效果，后续将会尝试切换到yet-another-react-lightbox。
            </Typography>

            <Divider />

            <FormControlLabel disabled control={<Checkbox checked />} label={<Typography variant="body1"><del>引入路由</del></Typography>} />
            <Typography variant="body2" gutterBottom>
                目前所有的子页面都是使用dialog的形式来展示，也因此导致了任意页面手势返回就会直接退出webapp的问题，于是引入路由分页面卫星。
            </Typography>

            <Divider />

            <FormControlLabel disabled control={<Checkbox checked />} label={<Typography variant="body1"><del>投稿反馈太弱</del></Typography>} />
            <Typography variant="body2" gutterBottom>
                目前网页投稿除了count会变动之外并没有任何反馈，而count的工作也称不上正常运作，我们需要加入能看见投稿图片和metadata的投稿确认窗，加强投稿反馈。
            </Typography>

            <Divider />

            <FormControlLabel disabled control={<Checkbox />} label={<Typography variant="body1">OSS对象储存</Typography>} />
            <Typography variant="body2" gutterBottom>
                图床盗链总有一天会走不下去，所以看看完善得差不多的时候就会开始自己储存图片，别把我流量跟钱包跑爆了兄弟们。
            </Typography>

            <Divider />

            <FormControlLabel disabled control={<Checkbox />} label={<Typography variant="body1">Material Design 3</Typography>} />
            <Typography variant="body2" gutterBottom>
                啥时候MUI更新了MD3之后这边也会尽快跟进，MUI官方预计是24年底，因为在Android12+机型上使用MD2设计实在是有点怪，感觉是回到了上个世纪。
            </Typography>

            <Divider /><br />

            <Typography variant="h6" gutterBottom>
                # Dream Time!
            </Typography>

            <FormControlLabel disabled control={<Checkbox />} label={<Typography variant="body1">APP?</Typography>} />
            <Typography variant="body2" gutterBottom>
                人总是要有梦想的，Mofumofu总有一天会是个真正的APP的。
                当然苹果App Store的上架费我肯定给不起，当然我也不会苹果开发，但是安卓万一呢？
            </Typography>

            <Divider />

            <FormControlLabel disabled control={<Checkbox />} label={<Typography variant="body1">Donation</Typography>} />

            <Alert severity="success">
                前面可能是假的，这条保真。
            </Alert>

            <Typography variant="body2" gutterBottom>
                Suspiciously wealthy furries速速v我50btc助力我全力投入开发，我的btc电子钱包地址是：███████████████████████（请使劲刮开涂层以便查看）
            </Typography>

            <Divider />

        </DialogContent>
    )
}