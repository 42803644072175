import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import { List } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import { useProps } from '../App';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import client from '../utils/Client'

export default function AppbarButton() {

  const {
    userInfo,
    updateUserInfo,
    filterOpen,
    setFilterOpen,
    layoutOpen,
    setLayoutOpen,
  } = useProps();

  const [loginOpen, setLoginOpen] = useState(false);
  const [signinOpen, setSigninOpen] = useState(false);
  const [logoutOpen, setLogoutOpen] = useState(false);
  const [loginsnackbarOpen, setLoginSnackbarOpen] = useState(false);
  const [signinsnackbarOpen, setSigninsnackbarOpen] = useState(false);

  // 登录&&注册提示Snack
  const handleLoginSnack = () => {
    setLoginSnackbarOpen(true);
  }
  const handleLoginSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setLoginSnackbarOpen(false);
  };
  const handleSigninSnack = () => {
    setSigninsnackbarOpen(true);
  }
  const handleSigninSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSigninsnackbarOpen(false);
  };

  // 登入&&注册表单
  const [loginFrom, setLoginForm] = useState({
    username: "",
    password: "",
  });
  const handleLoginChange = (e) => {
    setLoginForm({
      ...loginFrom,
      [e.target.name]: e.target.value,
    });
  }
  const [signinFrom, setSigninForm] = useState({
    username: "",
    email: "",
    password: "",
    password2: "",
  });
  const handleSigninChange = (e) => {
    setSigninForm({
      ...signinFrom,
      [e.target.name]: e.target.value,
    });
  }

  // 登入登出注册目录+dialog开关
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClickLogin = () => {
    setLoginOpen(true);
    setAnchorEl(null);
  };
  const handleClickSignin = () => {
    setSigninOpen(true);
    setAnchorEl(null);
  };
  const handleClickLogout = () => {
    setLogoutOpen(true);
    setAnchorEl(null);
  };
  const handleLoginClose = () => {
    setLoginOpen(false);
  };
  const handleSigninClose = () => {
    setSigninOpen(false);
  };
  const handleLogoutClose = () => {
    setLogoutOpen(false);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Layout&&Filter Dialog开关
  const handleLayout = () => {
    setLayoutOpen(!layoutOpen);
  };
  const handleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  // 登录登出注册fetch请求
  function handleLogin() {
    client.post(`/api/jwtauth/token/`, loginFrom)
      .then(({ data }) => {
        handleLoginSnack();
        localStorage.setItem('jwt_token', data.access);
        localStorage.setItem('jwt_token_refresh', data.refresh);
        updateUserInfo()
      })
      .catch(error => {
        console.error('Login error:', error);
      })
  }

  function handleLogout() {
    localStorage.clear();
    // window.location.reload();
    updateUserInfo(true)

    handleLogoutClose();

  }

  function handleSignin() {
    client.post(`/api/jwtauth/register/`, signinFrom)
      .then(({ data }) => {
        handleSigninSnack();
        localStorage.setItem('user', data.username);
        localStorage.setItem('jwt_token', data.access);
        localStorage.setItem('jwt_token_refresh', data.refresh);
        updateUserInfo()

      })
      .catch(error => {
        console.error('Signin error:', error);
      })
  }

  // 已登陆
  const loginMenu = (
    <>
      <IconButton color="inherit" onClick={handleFilter}>
        <FilterAltRoundedIcon />
      </IconButton>
      <IconButton color="inherit" onClick={handleLayout}>
        <DashboardRoundedIcon />
      </IconButton>
      <IconButton color="inherit" onClick={handleMenu}>
        <AccountCircle />
      </IconButton>

      {/* 登录和注册成功提示 */}
      <Snackbar
        open={loginsnackbarOpen}
        autoHideDuration={5000}
        onClose={handleLoginSnackClose}
        sx={{ bottom: { xs: 90, sm: 0 } }}
      >
        <Alert
          onClose={handleLoginSnackClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Login succeed, Welcome back!
        </Alert>
      </Snackbar>
      <Snackbar
        open={signinsnackbarOpen}
        autoHideDuration={5000}
        onClose={handleSigninSnackClose}
        sx={{ bottom: { xs: 90, sm: 0 } }}
      >
        <Alert
          onClose={handleSigninSnackClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Signin succeed, Welcome to mofumofu!
        </Alert>
      </Snackbar>

      {/* 登出 */}
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <List>
          <ListItem key={'Logout'} disablePadding>
            <ListItemButton onClick={handleClickLogout}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={'Logout'} />
            </ListItemButton>
          </ListItem>
        </List>
      </Menu>

      {/* 登出确认Dialog */}
      <Dialog
        open={logoutOpen}
        onClose={handleLogoutClose}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Logout"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogoutClose}>Cancel</Button>
          <Button onClick={handleLogout}>Logout</Button>
        </DialogActions>
      </Dialog>
    </>
  )

  // 未登录
  const logoutMenu = (
    <>
      <IconButton color="inherit" onClick={handleFilter}>
        <FilterAltRoundedIcon />
      </IconButton>
      <IconButton color="inherit" onClick={handleLayout}>
        <DashboardRoundedIcon />
      </IconButton>
      <IconButton color="inherit" onClick={handleMenu}>
        <AccountCircle color="disabled" />
      </IconButton>

      {/* 目录：登入&注册 */}
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <List>
          <ListItem key={'Login'} disablePadding>
            <ListItemButton onClick={handleClickLogin}>
              <ListItemIcon >
                <LoginIcon />
              </ListItemIcon>
              <ListItemText primary={'Login'} />
            </ListItemButton>
          </ListItem>
          <ListItem key={'Sign up'} disablePadding>
            <ListItemButton onClick={handleClickSignin}>
              <ListItemIcon>
                <PersonAddAltIcon />
              </ListItemIcon>
              <ListItemText primary={'Sign up'} />
            </ListItemButton>
          </ListItem>
        </List>
      </Menu>

      {/* 注册 */}
      <Dialog
        open={signinOpen}
        onClose={handleSigninClose}
        fullWidth={true}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleSigninClose();
          },
        }}
      >
        <DialogTitle>Sign up</DialogTitle>
        <DialogContent>

          <DialogContentText>
            ~Mofumofu~
          </DialogContentText>
          {[
            { id: "username", label: "Username", type: "string" },
            { id: "email", label: "Email", type: "email" },
            { id: "password", label: "Password", type: "password" },
            { id: "password2", label: "Confirm password", type: "password" },
          ].map(({ id, label, type }) => (
            <TextField
              required
              margin="dense"
              key={id}
              id={id}
              name={id}
              label={label}
              type={type}
              fullWidth
              variant="standard"
              onChange={handleSigninChange}
            />
          ))}

        </DialogContent>

        <DialogActions>
          <Button onClick={handleSigninClose}>Cancel</Button>
          <Button type="submit" disabled={(
            !signinFrom.password
            || !signinFrom.username
            || !signinFrom.email
            || signinFrom.password !== signinFrom.password2
          )} onClick={handleSignin} >Sign up</Button>
        </DialogActions>

      </Dialog>

      {/* 登录 */}
      <Dialog
        open={loginOpen}
        onClose={handleLoginClose}
        fullWidth={true}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleLoginClose();
          },
        }}
      >
        <DialogTitle>Login</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ~Mofumofu~
          </DialogContentText>

          {[
            { id: "username", label: "Username", type: "text" },
            { id: "password", label: "Password", type: "password" },
          ].map(({ id, label, type }) => (
            <TextField
              required
              margin="dense"
              key={id}
              id={id}
              name={id}
              label={label}
              type={type}
              fullWidth
              variant="standard"
              onChange={handleLoginChange}
            />
          ))}

        </DialogContent>
        <DialogActions>
          <Button onClick={handleLoginClose}>Cancel</Button>
          <Button type="submit" disabled={(!loginFrom.username || !loginFrom.password)} onClick={handleLogin} >Login</Button>
        </DialogActions>
      </Dialog>
    </>
  )

  if (userInfo) {
    return (loginMenu)
  } else {
    return (logoutMenu);
  }

}