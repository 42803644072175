import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AppbarButton from './AppbarButton'
import TemporaryDrawer from './Drawer'

export default function ButtonAppBar() {
  return (
    <AppBar sx={{ position: 'fixed' }}>
      <Toolbar>

        {/* 抽屉 */}
        <TemporaryDrawer />

        <Typography align="left" variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <b onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>Mofumofu Alpha</b>
        </Typography>

        {/* 三大金刚 */}
        <AppbarButton />

      </Toolbar>
    </AppBar>
  );
}