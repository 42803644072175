import React from 'react';
import Button from '@mui/material/Button';
import { useState } from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import client from '../utils/Client'
export default function ReviewButton({ item }) {
    const [sending, setSending] = useState(false)
    const [passed, setPassed] = useState(item.passed)
    const [nsfw, setNsfw] = useState(item.nsfw)
    const handlePass = (passed, nsfw) => {
        // 不抖了
        setSending(true)
        client.post(
            `/api/media/?id=${item.id}`,
            { 'approved': passed, "note": { "nsfw": nsfw } }
        )
            .then(({ data }) => {
                setNsfw(data.note.nsfw)
                setPassed(data.approved)
            })
            .catch(console.error)
            .finally(() => setSending(false))
    }

    const Buttons = [
        <Button
            key={`${item.id}_${passed ? "recall" : "pass"}`}
            color={passed ? "error" : "success"}
            onClick={() => handlePass(!passed, nsfw)}
            disabled={sending}
        >
            {passed ? "Recall" : "Pass"}
        </Button>,
        <Button
            key={`${item.id}_${nsfw ? "sfw" : "nsfw"}`}
            color={nsfw ? "success" : "error"}
            onClick={() => handlePass(passed, !nsfw)}
            disabled={sending}
        >
            {nsfw ? "SFW~" : "NSFW!"}
        </Button>
    ]

    return (
        <ButtonGroup variant="contained" aria-label="Basic button group" fullWidth>
            {Buttons}
        </ButtonGroup>
    )
}