import React, { useState } from 'react';
import Fab from '@mui/material/Fab';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

export default function ScrollToTopFab() {

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 600) {
            setVisible(true)
        }
        else if (scrolled <= 600) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const fabStyle = {
        position: 'fixed',
        bottom: 16,
        right: 16,
        display: visible ? 'flex' : 'none',
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <Fab color="primary" aria-label="top" onClick={scrollToTop} sx={fabStyle} >
            <ArrowUpwardIcon />
        </Fab>
    )
}