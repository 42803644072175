import React, { useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import InfiniteScroll from 'react-infinite-scroller';
import { handleLoadNext } from '../components/ImageLoader.js';
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Download from "yet-another-react-lightbox/plugins/download";
import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/styles.css";
import { Link, Typography } from '@mui/material';
import PhotoAlbum from "react-photo-album";
import Box from "@mui/material/Box";
import AlbumCards from '../components/AlbumCards.js';
import { useProps } from '../App';

export default function Home() {

    const {
        itemsData, setItemData,
        pageNum, setPageNum,
        hasMore, setHasMore,
        loading, setLoading,
        searchParams, setSearchParams,
        isReview,
        isNsfw,
        userInfo,
        isPending,
        layout, columns, targetRowHeight, spacing, padding, width
    } = useProps();
    const isAdmin = !!userInfo?.is_staff

    const [data, setData] = React.useState([]);
    const [lbindex, setLbIndex] = React.useState(-1);

    // 将itemsdata元数据转换为Lightbox用元数据
    useEffect(() => {
        const datas = itemsData.map(({ mime, media_url, width, height, artist_nickname, article, post_at, artist_social_id, id, approved, note }) => (
            mime.includes('image') ? {
                item: { id: id, passed: approved, nsfw: note['nsfw'] },
                origin: media_url,
                // 图片优先使用imgur
                src: (media_url.includes('twimg') ? media_url.slice(0, -4) + `?format=jpg&name=large` : media_url),
                width,
                height,
                title: null,
                username: artist_nickname,
                userid: artist_social_id,
                description:
                    <>
                        Artist: <Link href={article} target="_blank">{artist_nickname}</Link>
                        <br />
                        User_id: <Link onClick={() => setSearchParams(artist_social_id)} >@{artist_social_id}</Link>
                        <br />
                        Date: {post_at}
                        <br />
                        Resolution: {width} × {height}
                    </>,
                type: 'image',
            } : {
                item: { id: id, passed: approved, nsfw: note['nsfw'] },
                src: media_url,
                type: 'video',
                title: null,
                username: artist_nickname,
                userid: artist_social_id,
                description:
                    <>
                        Artist: <Link href={article} target="_blank">{artist_nickname}</Link>
                        <br />
                        User_id: <Link onClick={() => setSearchParams(artist_social_id)} >@{artist_social_id}</Link>
                        <br />
                        Date: {post_at}
                        <br />
                        Resolution: {width} × {height}
                    </>,
                width: width,
                height: height,
                poster: null,
                sources: [{
                    src: media_url,
                    type: "video/mp4",
                },],
                autoPlay: true,
                loop: true,
                muted: true,
            }
        ))
        console.log(datas);
        setData(datas);
    }, [itemsData, setSearchParams])

    return (

        <>
            {/* Lightbox */}
            <Lightbox
                index={lbindex}
                slides={data}
                open={lbindex >= 0}
                close={() => setLbIndex(-1)}
                plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom, Download, Counter]}
                // Thumbnails props
                carousel={{ preload: 5 }}
                thumbnails={{
                    width: 100,
                    border: 0,
                    showToggle: true,
                    gap: 0,
                }}
                // Captions props
                captions={{ descriptionMaxLines: 5 }}
                // Style
                styles={{
                    button: { "--yarl__button_padding": "6px", }
                }}
            />

            {/* 无限滚动 */}
            <Box sx={{ width: `${width}%`, mx: "auto" }}>
                <InfiniteScroll
                    pageStart={0}
                    initialLoad={false}
                    loadMore={loading ? () => null : () => handleLoadNext(
                        {
                            itemsData: itemsData,
                            setItemData: setItemData,
                            pageNum: pageNum,
                            setPageNum: setPageNum,
                            hasMore: hasMore,
                            setHasMore: setHasMore,
                            isNsfw: isNsfw,
                            isAdmin: isAdmin,
                            loading: loading,
                            setLoading: setLoading,
                            isPending: isPending,
                            searchParams: searchParams,
                        }
                    )}
                    hasMore={hasMore && itemsData.length >= 36}
                    loader={
                        <LinearProgress key={0} />
                    }
                >

                    <PhotoAlbum
                        layout={layout}
                        photos={data}
                        targetRowHeight={targetRowHeight}
                        spacing={spacing}
                        columns={columns}
                        padding={padding}
                        onClick={({ index: current }) => setLbIndex(current)}
                        renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto, imageProps }) => (
                            <AlbumCards
                                isAdmin={isAdmin}
                                isReview={isReview}
                                photo={photo}
                                wrapperStyle={wrapperStyle}
                                renderDefaultPhoto={renderDefaultPhoto}
                                imageProps={imageProps}
                                searchParams={searchParams}
                                setSearchParams={setSearchParams}
                            />
                        )}
                    />

                </InfiniteScroll>

                {(hasMore && itemsData.length >= 36) || itemsData.length === 0 ? <></> : <Typography variant='h6'>😊 Wow, you have seen it all! 😊</Typography>}

            </Box>
        </>

    )
}