import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import EditRoadRoundedIcon from '@mui/icons-material/EditRoadRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import UploadRoundedIcon from '@mui/icons-material/UploadRounded';
import Switch from '@mui/material/Switch';
import ContrastRoundedIcon from '@mui/icons-material/ContrastRounded';
import { Link } from "react-router-dom";
import { useProps } from '../App.js';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Avatar, ListItemAvatar } from '@mui/material';

export default function TemporaryDrawer() {

  const {
    userInfo,
    mode,
    setMode,
    isFollowSysTheme,
    setIsFollowSysTheme,
  } = useProps();

  const [open, setOpen] = useState(false);

  const user = userInfo?.username

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleFollow = () => {
    localStorage.setItem('ThemeFollow', !isFollowSysTheme);
    setIsFollowSysTheme(!isFollowSysTheme);
    window.location.reload();
  }

  const handleMode = () => {
    localStorage.setItem('NightMode', !mode);
    setMode(!mode);
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>

      {/* 用户信息 */}
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <AccountCircle />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={user ? `Welcome back~` : `Hello~`}
            secondary={user ? `${user}` : `Welcome to Mofumofu`}
          />
        </ListItem>
      </List>

      <Divider />

      {/* Home && Submit */}
      <List>
        {[

          { to: `/`, key: "Home", secondary: null, disabled: false, icon: <HomeRoundedIcon /> },
          { to: !!userInfo ? `/submit` : `#`, key: "Submit", secondary: "Login required", disabled: !userInfo, icon: <UploadRoundedIcon /> },

        ].map(({ to, key, secondary, disabled, icon }) => (
          <Link key={key} to={to} style={{ color: 'inherit', textDecoration: 'inherit' }}>
            <ListItem disablePadding>
              <ListItemButton disabled={disabled} >
                <ListItemIcon>
                  {icon}
                </ListItemIcon>
                <ListItemText primary={key} secondary={secondary} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>

      <Divider />

      {/* Roadmap && Telegram && About */}
      <List>
        {[

          { to: `/roadmap`, target: null, key: "Roadmap", icon: <EditRoadRoundedIcon /> },
          { to: `https://t.me/mesukemo`, target: '_blank', key: "Telegram", icon: <SendRoundedIcon /> },
          { to: `/about`, target: null, key: "About", icon: <InfoRoundedIcon /> },

        ].map(({ to, target, key, icon }) => (
          <Link key={key} to={to} target={target} style={{ color: 'inherit', textDecoration: 'inherit' }}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {icon}
                </ListItemIcon>
                <ListItemText primary={key} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>

      <Divider />

      {/* Theme controller */}
      <List>
        <ListItem key={"Theme"} disablePadding>
          <ListItemButton onClick={handleFollow}>
            <ListItemIcon>
              <ContrastRoundedIcon />
            </ListItemIcon>
            <ListItemText primary={"Theme"} secondary={"Follow system?"} />
            <Switch checked={isFollowSysTheme} onChange={handleFollow} />
          </ListItemButton>
        </ListItem>

        <ListItem key={"Mode"} disablePadding>
          <ListItemButton onClick={handleMode} disabled={isFollowSysTheme}>
            <ListItemIcon>
              {mode ? <LightModeRoundedIcon /> : <DarkModeRoundedIcon />}
            </ListItemIcon>
            <ListItemText primary={mode ? "Light Mode" : "Dark Mode"} />
          </ListItemButton>
        </ListItem>
      </List>

    </Box>
  );

  return (
    <div>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 2 }}
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}