import * as React from "react";

import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Drawer from '@mui/material/Drawer';
import { IconButton, Container, Typography } from "@mui/material";
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';

const useLayoutEffect = typeof document !== "undefined" ? React.useLayoutEffect : React.useEffect;

function Filter({ children }) {
    return (
        <Grid item xs={12} sm={8} lg={6}>
            {children}
        </Grid>
    );
}

function SliderControl({
    name,
    min,
    max,
    step,
    value,
    onChange,
    disabled,
}) {
    const [focused, setFocused] = React.useState(false);

    return (
        <FormControl margin="none" fullWidth>
            <InputLabel shrink variant="standard" focused={focused}>
                {name}
            </InputLabel>
            <Slider
                min={min}
                max={max}
                step={step}
                value={value}
                disabled={disabled}
                size="small"
                valueLabelDisplay="auto"
                marks={[
                    { value: min, label: `${min}` },
                    { value: max, label: `${max}` },
                ]}
                onChange={(e, value, activeThumb) => onChange(e, typeof value === "number" ? value : value[0], activeThumb)}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                sx={{ mt: 2 }}
            />
        </FormControl>
    );
}

const SettingsContext = React.createContext(null);

export function useSettings() {
    const context = React.useContext(SettingsContext);
    if (!context) throw new Error("useSettings must be used within a SettingsContext");
    return context;
}

export default function Settings({ layoutOpen, setLayoutOpen, children }) {

    const userSettings = JSON.parse(localStorage.getItem("userLayoutSettings"));
    const [layout, setLayout] = React.useState(userSettings ? userSettings.layout : "rows");
    const [targetRowHeight, setTargetRowHeight] = React.useState(userSettings ? userSettings.targetRowHeight : 350);
    const [columns, setColumns] = React.useState(userSettings ? userSettings.columns : 2);
    const [spacing, setSpacing] = React.useState(userSettings ? userSettings.spacing : 8);
    const [padding, setPadding] = React.useState(userSettings ? userSettings.padding : 0);
    const [width, setWidth] = React.useState(userSettings ? userSettings.width : 100);

    const toggleSettingsDrawer = (newOpen) => () => {
        setLayoutOpen(newOpen);
    };

    const settings = React.useMemo(
        () => ({
            layout,
            targetRowHeight,
            columns,
            spacing,
            padding,
            width,
        }),
        [layout, targetRowHeight, columns, spacing, padding, width],
    );

    useLayoutEffect(() => {
        localStorage.setItem("userLayoutSettings", JSON.stringify(settings));
        console.log("SET userLayoutSettings: ", JSON.stringify(settings));
    }, [settings])

    useLayoutEffect(() => {
        if (!userSettings) {
            const viewportSize = window.innerWidth;
            setColumns(viewportSize < 480 ? 2 : viewportSize < 900 ? 3 : 4);
            setSpacing(viewportSize < 480 ? 8 : viewportSize < 900 ? 8 : 8);
            setPadding(0);
            setTargetRowHeight(viewportSize < 480 ? 300 : viewportSize < 900 ? 350 : 400);
        }
    }, [userSettings]);

    return (
        <SettingsContext.Provider value={settings}>
            <Drawer anchor='top' open={layoutOpen} onClose={toggleSettingsDrawer(false)}>
                <Container maxWidth="lg" sx={{ padding: 4, paddingBottom: 0.5 }}>
                    <Typography variant="h6">Layout Settings</Typography>
                    <br />
                    <Grid container columns={24} rowSpacing={1} columnSpacing={4}>
                        <Filter>
                            <TextField
                                select
                                fullWidth
                                label="Layout"
                                variant="standard"
                                margin="none"
                                value={layout}
                                onChange={(event) => setLayout(event.target.value)}
                            >
                                {[
                                    { value: "rows", title: "Rows" },
                                    { value: "columns", title: "Columns" },
                                    { value: "masonry", title: "Masonry" },
                                ].map(({ value, title }) => (
                                    <MenuItem id="Layout" key={value} value={value}>
                                        {title}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Filter>

                        <Filter>
                            <SliderControl name="Spacing" min={0} max={24} value={spacing} onChange={(_, value) => setSpacing(value)} />
                        </Filter>

                        <Filter>
                            <SliderControl name="Padding" min={0} max={28} value={padding} onChange={(_, value) => setPadding(value)} />
                        </Filter>

                        <Filter>
                            <SliderControl
                                name="Row height"
                                min={200}
                                max={500}
                                step={5}
                                value={targetRowHeight}
                                disabled={layout !== "rows"}
                                onChange={(_, value) => setTargetRowHeight(value)}
                            />
                        </Filter>

                        <Filter>
                            <SliderControl
                                name="Columns"
                                min={1}
                                max={6}
                                value={columns}
                                disabled={layout === "rows"}
                                onChange={(_, value) => setColumns(value)}
                            />
                        </Filter>

                        <Filter>
                            <SliderControl
                                name="Width (%)"
                                min={10}
                                max={100}
                                step={5}
                                value={width}
                                onChange={(_, value) => setWidth(value)}
                            />
                        </Filter>
                    </Grid>
                </Container>
                <IconButton sx={{ alignSelf: 'center' }} onClick={toggleSettingsDrawer(false)} >
                    <KeyboardArrowUpRoundedIcon />
                </IconButton>
            </Drawer>

            {children}

        </SettingsContext.Provider>
    );
}
