import React from 'react';
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/styles.css";
import { Card, Typography } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import ReviewButton from './ReviewButton';

export default function AlbumCards({ photo, wrapperStyle, renderDefaultPhoto, imageProps, isAdmin, isReview, searchParams, setSearchParams }) {

    return (
        photo.type === "video" ?
            <Card style={{ position: "relative", ...wrapperStyle }}>
                <CardMedia>

                    {/* 自定义onError render */}
                    <video
                        src={photo.src}
                        {...imageProps}
                        style={{ width: '100%' }}
                        muted autoPlay loop
                        referrerPolicy='no-referrer'
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.alt = '😟 Original post has been deleted by the author. 😟';
                        }}
                    >
                    </video>

                    {/* isAdmin && isReview => 管理员审核按键 */}
                    {photo.username && isAdmin && isReview && (
                        <div
                            style={{
                                position: "absolute",
                                backgroundColor: "rgba(0 0 0 / .4)",
                                inset: "auto 0 0 0",
                                padding: 4,
                            }}
                        >
                            <Typography component={'div'} >
                                <ReviewButton item={photo.item} />
                            </Typography>
                        </div>
                    )}

                    {/* 普通用户 => 画师名Label + 点击检索 */}
                    {((photo.username && !isAdmin) || (photo.username && isAdmin && !isReview)) && (
                        <div
                            style={{
                                position: "absolute",
                                overflow: "hidden",
                                backgroundColor: "rgba(0 0 0 / .4)",
                                inset: "auto 0 0 0",
                                padding: 0,
                                maxHeight: 28,
                            }}
                        >
                            <Typography
                                color={'white'}
                                padding={0.5}
                                variant='body2'
                                key={photo.src}
                                onClick={() => setSearchParams(photo.userid)}
                            >
                                {photo.username}
                            </Typography>
                        </div>
                    )}

                </CardMedia>
            </Card>
            :
            <Card style={{ position: "relative", ...wrapperStyle }}>
                <CardMedia>

                    {/* 自定义onError Photorender */}
                    {/* {renderDefaultPhoto({ wrapped: true })} */}
                    <img
                        alt={photo.username}
                        {...imageProps}
                        style={{ width: '100%' }}
                        referrerPolicy='no-referrer'
                        onError={(e) => {
                            // imgur_url fallback
                            // 不能切origin，炸了的origin fallback回去就无限死循环
                            // e.target.src = photo.origin;
                            e.target.onerror = null;
                            e.target.alt = '😟 Original post has been deleted by the author. 😟';
                        }}
                    />

                    {/* isAdmin && isReview => 管理员审核按键 */}
                    {photo.username && isAdmin && isReview && (
                        <div
                            style={{
                                position: "absolute",
                                backgroundColor: "rgba(0 0 0 / .4)",
                                inset: "auto 0 0 0",
                                padding: 4,
                            }}
                        >
                            <Typography component={'div'} >
                                <ReviewButton item={photo.item} />
                            </Typography>
                        </div>
                    )}

                    {/* 普通用户 => 画师名Label + 点击检索 */}
                    {((photo.username && !isAdmin) || (photo.username && isAdmin && !isReview)) && (
                        <div
                            style={{
                                position: "absolute",
                                overflow: "hidden",
                                backgroundColor: "rgba(0 0 0 / .4)",
                                inset: "auto 0 0 0",
                                padding: 0,
                                maxHeight: 28,
                            }}
                        >
                            <Typography
                                color={'white'}
                                padding={0.5}
                                variant='body2'
                                key={photo.src}
                                onClick={() => setSearchParams(photo.userid)}
                            >
                                {photo.username}
                            </Typography>
                        </div>
                    )}

                </CardMedia>
            </Card>
    )
}