import axios from "axios";
import Cookies from "js-cookie";
import { CSRFTOKEN, JWT_TOKEN, JWT_TOKEN_REFRESH, X_CSRF_TOKEN } from "./Consts";

const getNewToken = async (refresh) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_ADDR}api/jwtauth/refresh/`,
            { refresh },
            {
                headers: {
                    'X-CSRFToken': Cookies.get(CSRFTOKEN)
                }
            }
        )
        localStorage.setItem(JWT_TOKEN, response.access);

    } catch (e) {
        console.error('Fetch error:', e);
        localStorage.removeItem(JWT_TOKEN_REFRESH)
        localStorage.removeItem(JWT_TOKEN)
    }

}

const client = axios.create({
    baseURL: process.env.REACT_APP_API_ADDR,
    timeout: 5000,
})

client.interceptors.request.use(config => {
    config.headers[X_CSRF_TOKEN] = Cookies.get(CSRFTOKEN)
    const token = localStorage.getItem(JWT_TOKEN);
    if (token) config.headers["Authorization"] = `Bearer ${token}`
    return config
})

client.interceptors.response.use(response => response, async error => {
    console.error("web request exception:", error)
    const refresh = localStorage.getItem(JWT_TOKEN_REFRESH);
    if (error?.response?.status === 403 && refresh) {
        const config = error?.config;
        await getNewToken(refresh)
        return axios.request(config)
    }
    return Promise.reject(error)
})

export default client;