import * as React from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';

export default function AboutDialogContent() {
    return (
        <DialogContent className='About' sx={{ textAlign: 'start' }}>

            <Typography variant="h4" gutterBottom>
                About Mofumofu
            </Typography>

            <Alert severity="warning">
                注意，以下内容可能只是舞草为了测试（玩）Mui排版而乱写的，请自行辨别内容的真实性。
            </Alert>

            <br />

            <Typography variant="h6" gutterBottom>
                # Mofumofu / Mesukemo
            </Typography>
            <Typography variant="body1" gutterBottom>
                Mofumofu取自于日语里的「モフモフ」，也就是毛茸茸的意思。
                而Mesukemo也类似，是日语里的「メスケモ」，也就是母福瑞的意思。
            </Typography>
            <Typography variant="body1" gutterBottom>
                一开始，Mofumofu只是一个在小飞机上用于保存开发者喜欢的福瑞生物推文的频道，当时的保存内容只有干巴巴的一个推特链接。
                后来逐渐聚集了一些同样喜欢母福瑞的订阅者，包括下面提到的Team Lead，并开始引入了各种自动/半自动程序，并发展至今。
            </Typography>

            <Divider /><br />

            <Typography variant="h6" gutterBottom>
                # Alpha Version
            </Typography>
            <Typography variant="body1" gutterBottom>
                Mofumofu目前处于一个几乎可以说是Prototype的阶段，对于管理者来说只实现了简单的浏览、投稿和基础管理功能，对于浏览者来说更是只有浏览和简陋的投稿功能。
                因此，在有进一步的功能和体验完善之前，本站将会一直以Mofumofu Alpha为名。
            </Typography>

            <Divider /><br />

            <Typography variant="h6" gutterBottom>
                # Developers
            </Typography>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar alt="舞草" src="https://i.imgur.com/8ZT3yYX.jpg" >舞草</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="舞草"
                        secondary={
                            <React.Fragment>
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    Channel Owner
                                </Typography>
                                {" — 噢，是这只不明生物创建了Mofumofu频道。受下方另外一只不明生物的启发和帮助，现在能写一些简单的前后端。"}
                            </React.Fragment>
                        }
                    />
                </ListItem>
                <Divider variant="middle" component="li" />
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar alt="Steins;Gat ☈" src="https://i.imgur.com/um3WEYM.jpg" >Steins;Gat ☈</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="Steins;Gat ☈"
                        secondary={
                            <React.Fragment>
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    Team Lead
                                </Typography>
                                {" — 全栈的神，帮忙改了舞草整出来的很多Shitcode。没有TA就没有频道自动化订阅Bot，没有优雅的投稿格式，没有使用ReactJS的本网页，更没有能写代码的舞草。"}
                            </React.Fragment>
                        }
                    />
                </ListItem>
            </List>

            <Divider /><br />

            <Typography variant="h6" gutterBottom>
                # Pics
            </Typography>
            <Typography variant="body1" gutterBottom>
                目前，Mofumofu网页上所有的图片都直接取用于推特/FA图床。这意味着原推文/博主的状态一旦发生改变（包括但不限于删帖/锁推/删推等情况）
                或推特的防盗链政策出现了修改，本站站内的相应图片也会变得不可访问。因此，如果看到喜欢的图片，请务必先保存下来。
            </Typography>

            <Divider /><br />

            <Typography variant="h6" gutterBottom>
                # DMCA / Contact
            </Typography>
            <Typography variant="body1" gutterBottom>
                目前所有联系均可通过Telegram群组/传话机器人实现，点击侧栏的Telegram按钮即可直达频道。
            </Typography>

        </DialogContent>
    )
}