import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Download from "yet-another-react-lightbox/plugins/download";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, LinearProgress, Link } from '@mui/material';
import PhotoAlbum from "react-photo-album";
import AlbumCards from '../components/AlbumCards.js';
import { useProps } from '../App';
import client from '../utils/Client'

export default function Submit() {

    const {
        userInfo,
        searchParams, setSearchParams,
        isReview,
        layout, columns, targetRowHeight, spacing, padding, width
    } = useProps();
    const isAdmin = !!userInfo?.is_staff
    // Album用
    const [submitData, setSubmitData] = React.useState([]);
    const [lbindex, setLbIndex] = React.useState(-1);

    const [submitterOpen, setSubmitterOpen] = useState(false);
    const [submitItemsData, setSubmitItemsData] = useState([]);
    const [url, setURL] = useState('');
    const [valid, setValid] = useState(true);

    const [isLoading, setIsLoading] = useState(false);
    const [isPolling, setIsPolling] = useState(false);

    // 将itemsdata元数据转换为Lightbox用元数据
    useEffect(() => {
        // const datas = submitItemsData.map(({ content_type, media_url, width, height, username, url, post_time, userid, id, passed, nsfw }) => (
        const datas = submitItemsData.map(({ mime, media_url, width, height, artist_nickname, article, post_at, artist_social_id, id, approved, note }) => (
            mime.includes('image') ? {
                item: { id: id, passed: approved, nsfw: note['nsfw'] },
                origin: media_url,
                // 图片优先使用imgur
                src: (media_url.includes('twimg') ? media_url.slice(0, -4) + `?format=jpg&name=large` : media_url),
                width,
                height,
                title: null,
                username: artist_nickname,
                userid: artist_social_id,
                description:
                    <>
                        Artist: <Link href={article} target="_blank">{artist_nickname}</Link>
                        <br />
                        User_id: @{artist_social_id}
                        <br />
                        Date: {post_at}
                        <br />
                        Resolution: {width} × {height}
                    </>,
                type: 'image',
            } : {
                item: { id: id, passed: approved, nsfw: note['nsfw'] },
                src: media_url,
                type: 'video',
                title: null,
                username: artist_nickname,
                userid: artist_social_id,
                description:
                    <>
                        Artist: <Link href={article} target="_blank">{artist_nickname}</Link>
                        <br />
                        User_id: @{artist_social_id}
                        <br />
                        Date: {post_at}
                        <br />
                        Resolution: {width} × {height}
                    </>,
                width: width,
                height: height,
                poster: null,
                sources: [{
                    src: media_url,
                    type: "video/mp4",
                },],
                autoPlay: true,
                loop: true,
                muted: true,
            }
        ))
        console.log(datas);
        setSubmitData(datas);

    }, [submitItemsData])

    const fabStyle = {
        position: 'fixed',
        bottom: 16,
        right: 16,
    };

    const handleSubmitterClose = () => {
        setURL('');
        setSubmitterOpen(false);
    };

    const handleSubmitterOpen = () => {
        setURL('');
        setSubmitterOpen(true);
    };

    // 旧的单条轮询
    // const handlepolling = ({ task_ids }) => {
    //     task_ids.forEach(element => {
    //         console.log(`Try: ${element}`)
    //         client.get(
    //             `/api/polling/?task_id=${element}`
    //         )
    //             .then(({ data }) => {
    //                 if (data.status === 'PENDING') {
    //                     setTimeout(function () {
    //                         handlepolling({ task_ids: [element] });
    //                     }, 5000)
    //                 } else if (data.status === 'SUCCESS') {
    //                     console.log(`Polling result: ${data.result}`);
    //                     setSubmitItemsData(pre => pre.concat(data.result));
    //                 }
    //             })
    //             .catch(error => {
    //                 console.error('Polling Failed! Error:', error);
    //             })
    //     });
    // }

    const handlepolling = ({ task_ids }) => {

        client.post(
            `/api/polling/`,
            { 'task_ids': task_ids },
        )
            .then(({ data }) => {
                if (data.results.length !== 0) {
                    console.log(`Polling result: ${data.results}`);
                    setSubmitItemsData(pre => pre.concat(data.results));
                }
                if (data.status.includes('PENDING')) {
                    setTimeout(function () {
                        handlepolling({ task_ids: data.task_ids });
                    }, 3000)
                } else {
                    setIsPolling(false);
                }
            })
            .catch(error => {
                console.error('Polling Failed! Error:', error);
                setIsPolling(false);
            })
        
    }

    const handleSubmit = () => {

        // 非法url报错
        try {
            const newUrl = new URL(url);
            if (!(newUrl.protocol === 'http:' || newUrl.protocol === 'https:')) {
                setValid(false);
                return
            }
        } catch (err) {
            setValid(false);
            return err
        }

        // 不抖了
        setIsLoading(true);
        client.post(
            isAdmin ? `/api/submission/?directpass=true` : `/api/submission/`,
            { 'url': [...new Set(url.split(/[\s\n]/))] },
            // 覆盖timeout，给更长时间submit
            // { timeout: url.split(/[\s\n]/).length * 5000 },
        )
            .then(({ data }) => {
                setIsLoading(false);
                console.log(data);
                setSubmitItemsData(submitItemsData.concat(data.exists));
                setIsPolling(true);
                handlepolling({ task_ids: data.task_ids });
            })
            .catch(error => {
                console.error('Submit Failed! Error:', error);
            })
            .finally(() => {
                setIsLoading(false);
            })

    }

    return (

        <>  
            <Typography variant="body1" gutterBottom textAlign='center' sx={submitItemsData.length !== 0 ? { display: 'none' } : {}}>
                {`Submit some links!`}
                <br />
                {`We support Twitter/FA at present.`}
            </Typography>

            <Fab color="primary" aria-label="add" onClick={handleSubmitterOpen} sx={fabStyle}>
                <AddIcon />
            </Fab>

            {/* <SubmitContent items={items} isAdmin={isAdmin} /> */}
            <Lightbox
                index={lbindex}
                slides={submitData}
                open={lbindex >= 0}
                close={() => setLbIndex(-1)}
                plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom, Download, Counter]}
                // Thumbnails props
                carousel={{ preload: 5 }}
                thumbnails={{
                    width: 100,
                    border: 0,
                    showToggle: true,
                    gap: 0,
                }}
                // Captions props
                captions={{ descriptionMaxLines: 5 }}
                // Style
                styles={{
                    button: { "--yarl__button_padding": "6px", }
                }}
            />

            <Box sx={{ width: `${width}%`, mx: "auto" }}>

                {isPolling ? <LinearProgress key={'LinearProgress'} /> : <></>}

                <PhotoAlbum
                    layout={layout}
                    photos={submitData}
                    targetRowHeight={targetRowHeight}
                    spacing={spacing}
                    columns={columns}
                    padding={padding}
                    onClick={({ index: current }) => setLbIndex(current)}
                    renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto, imageProps }) => (
                        <AlbumCards
                            isAdmin={isAdmin}
                            isReview={isReview}
                            photo={photo}
                            wrapperStyle={wrapperStyle}
                            renderDefaultPhoto={renderDefaultPhoto}
                            imageProps={imageProps}
                            searchParams={searchParams}
                            setSearchParams={setSearchParams}
                        />
                    )}
                />
            </Box>

            {/* Submitter */}
            <Dialog
                open={submitterOpen}
                onClose={handleSubmitterClose}
                fullWidth={true}
            >

                <DialogTitle>Submit</DialogTitle>

                <DialogContent>

                    <DialogContentText variant='caption'>
                        Separate the links with a newline if you need multiple submit at once.
                    </DialogContentText>

                    <TextField
                        error={!valid}
                        helperText={!valid ? 'Invalid Url!' : ''}
                        autoFocus
                        required
                        margin="dense"
                        id="url"
                        name="url"
                        label="Submit url(s)"
                        type="url"
                        fullWidth
                        variant="standard"
                        onChange={e => { setURL(e.target.value) }}
                        multiline
                        maxRows={8}
                    />

                    <DialogActions>
                        <Button onClick={handleSubmitterClose}>Close</Button>
                        <LoadingButton
                            disabled={(!url)}
                            onClick={handleSubmit}
                            endIcon={<SendIcon />}
                            loading={isLoading}
                            loadingPosition="end"
                            variant="text"
                        >
                            Send
                        </LoadingButton>
                    </DialogActions>

                </DialogContent>
            </Dialog>
        </>

    )
}