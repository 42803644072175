import { useProps } from '../App';
import { Box, Button, Container, Drawer, FormControlLabel, IconButton, MenuItem, Switch, TextField, Typography } from '@mui/material';
import Grid from "@mui/material/Grid";
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { useState, useLayoutEffect } from 'react';
import client from '../utils/Client'

function Filter({ children }) {
    return (
        <Grid item xs={12} sm={8} lg={6}>
            {children}
        </Grid>
    );
}

export function getParams({ isAdmin, isPending, isNsfw, pageNum, searchParams }) {

    const params = {};

    if (searchParams !== '') {
        params.artist_social_id = searchParams;
    }
    if (!isAdmin) {
        params.approved = true;
    }
    if (isAdmin && !isPending) {
        params.approved = true;
    }
    if (isAdmin && isPending) {
        params.approved = false;
    }
    if (isNsfw === "both" && params.nsfw) {
        delete params.nsfw
    }
    if (isNsfw === "false") {
        params.nsfw = false;
    }
    if (isNsfw === "true") {
        params.nsfw = true;
    }
    params.page = pageNum;

    return new URLSearchParams(params);

}

export const handleLoadNext = ({ itemsData, setItemData, pageNum, setPageNum, hasMore, setHasMore, isNsfw, isAdmin, loading, setLoading, isPending, searchParams }) => {

    if (!loading) {
        setLoading(true);
        client.get(`/api/media/?${getParams({ isAdmin, isPending, isNsfw, pageNum, searchParams })}`)
            .then(({ data }) => {
                setLoading(false);
                setHasMore(data['next'] ? true : false);
                console.log(itemsData.concat(data['results']));
                setItemData(itemsData.concat(data['results']));
                setPageNum(pageNum + 1);
            })
            .catch(error => {
                setLoading(false);
                setHasMore(false);
                console.error('Fetch error:', error);
            })
            .finally(() => setLoading(false))
    }
}

export default function ImageFilter() {

    const {
        userInfo,
        isPending, setIsPending,
        isReview, setIsReview,
        isNsfw, setIsNsfw,
        filterOpen, setFilterOpen,
        searchParams, setSearchParams,
    } = useProps();
    const isAdmin = !!userInfo?.is_staff

    const toggleSettingsDrawer = (newOpen) => () => {
        setFilterOpen(newOpen);
    };

    const [temporary, setTemporary] = useState('');

    useLayoutEffect(() => {
        localStorage.setItem("userNsfw", isNsfw);
        console.log("SET userNsfw: ", isNsfw);
    }, [isNsfw])

    return (

        <Drawer anchor='top' open={filterOpen} onClose={toggleSettingsDrawer(false)}>
            <Container maxWidth="lg" sx={{ padding: 4, paddingBottom: 0.5 }}>
                <Typography variant="h6">Filter</Typography>
                <br />
                <Grid container columns={24} rowSpacing={1} columnSpacing={4}>

                    {isAdmin ?
                        <>
                            <Filter>
                                <FormControlLabel
                                    control={<Switch id="Pending" checked={isPending} onChange={() => { setIsPending(!isPending) }} />}
                                    label="Pending"
                                />
                            </Filter>
                            <Filter>
                                <FormControlLabel
                                    control={<Switch id="Review" checked={isReview} onChange={() => { setIsReview(!isReview) }} />}
                                    label="Review"
                                />
                            </Filter>
                        </>
                        :
                        <>
                        </>
                    }
                    <Filter>
                        <TextField
                            select
                            fullWidth
                            label="NSFW?"
                            variant="standard"
                            margin="none"
                            value={isNsfw}
                            onChange={(event) => setIsNsfw(event.target.value)}
                        >
                            {[
                                { value: 'true', title: "NSFW" },
                                { value: 'false', title: "SFW" },
                                { value: 'both', title: "BOTH" },
                            ].map(({ value, title }) => (
                                <MenuItem id={title} key={value} value={value}>
                                    {title}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Filter>
                    <Filter>
                        <Box
                            component="form"
                            id='search artist'
                            noValidate
                            autoComplete="off"
                            onSubmit={(e) => {
                                e.preventDefault();
                                setSearchParams(temporary);
                            }}
                        >
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                margin="none"
                                label="Search artist"
                                variant="standard"
                                value={searchParams === '' ? temporary : searchParams}
                                helperText='e.g. spommmm'
                                onChange={(event) => setTemporary(event.target.value)}
                            />
                            <Button
                                fullWidth
                                variant="text"
                                onClick={
                                    () => {
                                        setSearchParams('');
                                        setTemporary('');
                                    }
                                }
                            >
                                Reset Search
                            </Button>
                        </Box>
                    </Filter>

                </Grid>
            </Container>

            <IconButton sx={{ alignSelf: 'center' }} onClick={toggleSettingsDrawer(false)} >
                <KeyboardArrowUpRoundedIcon />
            </IconButton>

        </Drawer>

    )

}